<template>
  <App
    title="金融单"
    right-text="创建金融单"
    left-arrow
    @click-right="onClickRight"
  >
    <Card
      class="list"
      v-for="$e in dataList"
      :key="$e.no"
    >
      <div
        class="card-info"
        slot="title"
      >
        <h3 class="name">No.{{$e.orderFinanceCode}}
          <a 
            v-clipboard:copy="$e.orderFinanceCode"
              v-clipboard:success="onCopy"
              href="javascript:;"
              class="link-text"
          >复制</a>
        </h3>
        <span class="tag">{{$e.financeAuditStatus}}</span>
      </div>
      <div class="info">
        <div>金融方案：{{$e.fiCode}}</div>
        <div>首付金额：{{$e.downPaymentAmount}}</div>
        <div>贷款金额：{{$e.loanPaymentAmount}}</div>
        <div>尾款金额：{{$e.balancePaymentAmount}}</div>
        <div>分期数：{{$e.nper}}</div>
        <div>贷款城市：{{$e.cityName}}</div>
        <div>贷款银行：{{$e.fiOrgCode}}</div>
      </div>
    </Card>
    <div
      class="noData"
      v-if="isNoData"
    >暂无数据</div>
  </App>
</template>
<script>
import Card from '_c/card'
import { loanorderlist } from '@/api/order'
import { Toast } from 'vant'

export default {
  name: 'financialOrder',
  components: { Card },
  data () {
    return {
      isNoData: false,
      orderInfo: {},
      dataList: []
    }
  },
  methods: {
    onClickRight () {
      if (['01', '03', '04', '05', '06', '99'].includes(this.$route.query.status)) {
        Toast('该订单状态不能创建金融单')
      } else {
        this.$router.push({
          path: '/createFinancialOrder',
          query: {
            orderNum: this.orderInfo.orderNum
          }
        })
      }


    },
    getData () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      const params = {
        orderNum: this.orderInfo.orderNum,
        channel: this.$storage.get('channel')
      }
      loanorderlist(params).then(res => {
        if (res.success) {
          this.dataList = res.data
          this.isNoData = this.dataList.length === 0
        }
      }).finally(() => {
        Toast.clear()
      })
    },
    onCopy () {
      Toast('复制成功!')
    }
  },
  mounted () {
    this.orderInfo = this.$route.query || {}
    this.getData()
  }
}
</script>
<style lang="less" scoped>
.list {
  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      width: 500px;
      margin-right: 20px;
    }

    .tag {
      display: inline-block;
      padding: 0 10px;
      color: @blue;
      font-size: 12px;
      height: 42px;
      line-height: 42px;
    }
  }
  .info {
    font-size: 24px;
    color: #666;
    div {
      height: 34px;
    }
  }
}
.noData {
  color: #969799;
  font-size: 14px;
  line-height: 100px;
  text-align: center;
}
</style>
